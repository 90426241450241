import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  deleteDoc,
  doc,
  query,
  startAfter,
  where,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase/firebase-config";

export const Filters = () => {
  const [data, setData] = useState([]);
  const qs = query(collection(db, "filters"));
  const filtersRef = collection(db, "filters");

  const [filter, setFilter] = useState("");
  const [edit, setEdit] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  useEffect(() => {
    fetchFilters();
  }, []);

  const fetchFilters = async () => {
    try {
      const querySnapshot = await getDocs(qs);
      setData(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };
  const addFilter = async () => {
    await addDoc(filtersRef, {
      name: filter,
    });
    setFilter("");
    setData((prev) => [...prev, { name: filter }]);
  };

  const deleteFilter = async (id) => {
    await deleteDoc(doc(db, "filters", id));
    setData((prev) => prev.filter((v) => v.id != id));
  };
  const editFilter = async () => {
    const docRef = doc(db, "filters", selectedFilter);
    await updateDoc(docRef, { name: filter });
    setData((prev) =>
      prev.map((v) => {
        if (v.id == selectedFilter) {
          return { name: filter, id: v.id };
        }
        return v;
      })
    );
    setEdit(false);
    setFilter("");
    setSelectedFilter(null);
  };
  return (
    <Box p={3}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <h2>Filters</h2>
        <Box>
          <TextField
            label={edit ? "Edit Filter" : "Add Filter"}
            variant="outlined"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <Button
            onClick={() => {
              edit ? editFilter() : addFilter();
            }}
          >
            {edit ? "Edit" : "Add"}
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} sx={{ minWidth: 200 }}>
                Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 &&
              data.map((v) => (
                <TableRow key={v.id}>
                  <TableCell>{v.name}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        setEdit(true);
                        setSelectedFilter(v.id);
                        setFilter(v.name);
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => deleteFilter(v.id)}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
