import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Skeleton,
  Paper,
  Stack,
  CardContent,
  Card,
} from "@mui/material";

import NoProductsFound from "./noproducts";
import StoneCard from "./product";
import {
  collection,
  query,
  where,
  getDocs,
  limit,
  startAfter,
  limitToLast,
  orderBy,
  endBefore,
  doc,
  getDoc,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase/firebase-config";
import { useLocation, useNavigate, useParams, NavLink } from "react-router-dom";
import { ProviderContext } from "../layout/providerCtx";

const pageSize = 50; // Number of items per page

export default function ListStones(props) {
  const { brandName, setBrandName, brandImg, setBrandImg } =
    useContext(ProviderContext);
  const { providerId } = useParams();
  const qs = query(collection(db, "filters"));
  const [data, setData] = useState([]);
  const fetchFilters = async () => {
    try {
      const querySnapshot = await getDocs(qs);
      setData(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };
  let StoneParametersRef = query(collection(db, "stones"), orderBy("name"));

  if (!providerId) {
    StoneParametersRef = query(collection(db, "stones"), orderBy("name"));
  } else {
    StoneParametersRef = query(
      collection(db, "stones"),
      where("uploadedBy", "==", providerId),
      orderBy("uploadedBy"),
      orderBy("name")
    );
  }

  const [loading, setLoading] = useState(true);
  // const [filterItems, setFilterItems] = useState([])
  const [filters, setFilters] = useState({});
  const [stones, setStone] = useState([]);
  const [finalq, setFinalq] = useState(StoneParametersRef);
  const [currentQuery, setCurrentQuery] = useState(StoneParametersRef);
  const [lastDoc, setLastDoc] = useState();
  const [firstDoc, setFirstDoc] = useState();
  const [page, setpage] = useState(1);

  const fetchPage = async (q) => {
    setLoading(true);
    try {
      if (page == 1) {
        q = query(q, limit(pageSize));
      }
      const querySnapshot = await getDocs(q);
      // querySnapshot.docs.map((doc) => alert(JSON.stringify(doc.data().uploadedBy)))
      // querySnapshot.docs.map((doc) => alert(JSON.stringify({ ...doc.data(), id: doc.id })))
      setStone(
        querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      const firstVisibleDoc = querySnapshot.docs[0];

      setLastDoc(lastVisible);
      setFirstDoc(firstVisibleDoc);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Error");
    }
  };

  const buildQuery = () => {
    let baseQuery = StoneParametersRef;
    for (const key in filters) {
      const value = filters[key];
      if (value !== "") {
        baseQuery = query(baseQuery, where(key, "==", value));
        console.log(baseQuery);
      }
    }

    return baseQuery;
  };

  const handleLoadMore = () => {
    let finalQuery = query(finalq, limit(pageSize), startAfter(lastDoc));
    setpage(page + 1);
    // setCurrentQuery(finalQuery)
    fetchPage(finalQuery);
  };

  const handlePrevious = () => {
    let finalQuery = query(finalq, limitToLast(pageSize), endBefore(firstDoc));
    setpage(page - 1);
    // setCurrentQuery(finalQuery)
    fetchPage(finalQuery);
  };

  useEffect(() => {
    fetchPage(currentQuery);
  }, [currentQuery]);

  useEffect(() => {
    const dynamicQuery = buildQuery();
    setCurrentQuery(dynamicQuery);
    setFinalq(dynamicQuery);

    fetchFilters();
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleApplyFilter = async () => {
    console.log("Started Query Building");
    const dynamicQuery = buildQuery();
    setCurrentQuery(dynamicQuery);
  };

  const BrandCard = ({ providerId }) => {
    const {
      brandName,
      setBrandName,
      brandImg,
      setBrandImg,
      brandId,
      setBrandId,
    } = useContext(ProviderContext);
    // const [brandName, setBrandName] = useState("Stone Provider")
    // const [brandImg, setBrandImg] = useState("")

    useEffect(() => {
      const fetchProvider = async () => {
        const docRef = doc(db, "Brands", providerId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setBrandName(docSnap.data().name);
          setBrandId(providerId);
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document fr company name!");
        }

        try {
          const storage = getStorage();
          const storageRef = ref(storage, `Brands/${providerId}/logo.png`);
          const url = await getDownloadURL(storageRef);

          // alert(url)
          setBrandImg(url);
        } catch (error) {
          // Handle any errors
          console.error("Error downloading image:", error);
        }
      };
      if (providerId !== "" && providerId !== "undefined") {
        fetchProvider();
      }
    }, []);

    return (
      <Box>
        <Box
          px={4}
          py={6}
          sx={{
            backgroundColor: "#d3d3d3",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              backgroundColor: "#d3d3d3",
              fontWeight: "bold",
              marginBottom: 2,
              // color: '#f0f0f0'
              color: "#232326",
            }}
          >
            {brandName}
          </Typography>

          <img
            src={brandImg}
            alt={brandName}
            height={"120px"}
            width={"120px"}
          ></img>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      {providerId && <BrandCard providerId={providerId} />}

      <Box
        sx={{
          backgroundColor: "#d3d3d3",
          px: 2,
          py: 2,
          color: "black", //boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          marginBottom: "1rem",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {loading ? (
          <Skeleton height={200} width={"70%"} />
        ) : (
          <>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              Filter Stones
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
                <InputLabel htmlFor="brand">Stone Brand</InputLabel>
                <Select
                  label="Brand"
                  name="brand"
                  value={filters.brand}
                  onChange={handleFilterChange}
                >
                  <MenuItem value="">All Brands</MenuItem>
                  {data.map((v) => (
                    <MenuItem key={v.id} value={v.name}>
                      {v.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
                <InputLabel htmlFor="finish">Slab Finish</InputLabel>
                <Select
                  label="Finish"
                  name="finish"
                  value={filters.finish}
                  onChange={handleFilterChange}
                >
                  <MenuItem value="">All Finishes</MenuItem>
                  <MenuItem value="Polished">Polished</MenuItem>
                  <MenuItem value="Honed">Honed</MenuItem>
                  <MenuItem value="Matt">Matt</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
                <InputLabel htmlFor="size">Slab Size</InputLabel>
                <Select
                  label="Size"
                  name="size"
                  value={filters.size}
                  onChange={handleFilterChange}
                >
                  <MenuItem value="">All Sizes</MenuItem>
                  <MenuItem value="Regular">Regular</MenuItem>
                  <MenuItem value="Grande">Grande</MenuItem>
                  <MenuItem value="Jumbo">Jumbo</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
                <InputLabel htmlFor="color">Colour Tone</InputLabel>
                <Select
                  label="Color"
                  name="color"
                  value={filters.color}
                  onChange={handleFilterChange}
                >
                  <MenuItem value="">All Colour Tones</MenuItem>
                  <MenuItem value="Classic Whites">Classic Whites</MenuItem>
                  <MenuItem value="Warm Whites">Warm Whites</MenuItem>
                  <MenuItem value="Cool Whites">Cool Whites</MenuItem>
                  <MenuItem value="Cream Tones">Cream Tones</MenuItem>
                  <MenuItem value="Coffee Tones">Coffee Tones</MenuItem>
                  <MenuItem value="Light Grey Tones">Light Grey Tones</MenuItem>
                  <MenuItem value="Dark Grey Tones">Dark Grey Tones</MenuItem>
                  <MenuItem value="Blacks">Blacks</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
                <InputLabel htmlFor="color_pattern">Colour Pattern</InputLabel>
                <Select
                  label="Pattern"
                  name="color_pattern"
                  value={filters.color_pattern}
                  onChange={handleFilterChange}
                >
                  <MenuItem value="">All Colour Patterns</MenuItem>
                  <MenuItem value="Solid">Solid</MenuItem>
                  <MenuItem value="Fine Chips">Fine Chips</MenuItem>
                  <MenuItem value="Small Chips">Small Chips</MenuItem>
                  <MenuItem value="Medium Chips">Medium Chips</MenuItem>
                  <MenuItem value="Large Chips">Large Chips</MenuItem>
                  <MenuItem value="Swirl Pattern">Swirl Pattern</MenuItem>
                  <MenuItem value="Subtle Veins">Subtle Veins</MenuItem>
                  <MenuItem value="Strong Veins">Strong Veins</MenuItem>
                </Select>
              </FormControl>

              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyFilter}
                sx={{ m: 1, minWidth: 220 }}
              >
                Apply Filter
              </Button>
            </Box>
          </>
        )}
      </Box>

      <Box>
        <Container>
          {stones && stones.length > 0 ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  // backgroundColor: '#d3d3d3',
                  // backgroundColor: '#232326',
                }}
              >
                {stones &&
                  stones.map((stone) => {
                    return (
                      <StoneCard
                        id={stone.id}
                        key={stone.id}
                        name={stone.name}
                        image={stone.image}
                        width={stone.width}
                        size={stone.size}
                        collection={stone.range}
                        Tones={stone.Tones}
                        price={stone.price}
                        stone={stone}
                      />
                    );
                  })}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", p: 5 }}>
                <Stack direction="row" spacing={2}>
                  <Button
                    disabled={page == 1 || loading}
                    variant="contained"
                    onClick={handlePrevious}
                  >
                    Previous
                  </Button>
                  <Button>{page}</Button>
                  <Button
                    disabled={stones.length < pageSize || loading}
                    variant="contained"
                    onClick={handleLoadMore}
                  >
                    Next
                  </Button>
                </Stack>
              </Box>
            </>
          ) : (
            <NoProductsFound />
          )}
        </Container>
      </Box>
    </Box>
  );
}
